<template>
    <div class="title_conts" id="TOKENOMICS">
        <img src="../assets/images/token_tit.svg" alt="">
    </div>
    <div class="container main">
        <div class="left">
            <div class="text_tit">
                <h2>Token Supply:</h2>
                <div class="numbg">420,690,000,000,000</div>
            </div>
            <div class="card">
                <div class="box">
                    <h3>No Taxes, No Bullshit. It’s that simple.</h3>
                    <p>93.1% of the tokens were sent to the liquidity PIPIl LP tokens were burnt, and contract is renounced.
                        The remaining 6.9% of the supply is being held in a multi-sig wallet only to be used as tokens for
                        future centralized exchange listings, bridges, and liquidity PIPIls. This wallet is easily trackable
                        with the ENS name “PIPIcexwallet.eth”</p><br>
                </div>
            </div>
        </div>
        <div class="riht">
            <div class="pic">
                <div class="text text1">LOW taxes?<br>feels good<br>man</div>
                <!-- <div class="text text2">liquidity: 33%</div> -->
                <div class="text text3">
                    no taxes?<br>
                    feels good<br>
                    man</div>
                <img src="../assets/images/ggbundr.png" alt="">
            </div>
        </div>
        
        <div class="pic1">
            <img src="../assets/images/pic1.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
}
</script>

<style lang="less" scoped>
.title_conts {
    text-align: center;
    margin-top: 120px;

    img {
        width: 20%;
    }
}

.main {
    max-width: 1300px;
}

.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    padding-bottom: 20px;

    .pic1 {
        position: absolute;
        width: 200px;
        left: 0;
        bottom: 0;
        transform: translate(-85%,20%);
        img {
            width: 100%;
        }
    }
    .imgs {
        position: absolute;
        left: -52%;
        bottom: -50%;
    }

    .text_tit {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        font-size: 26px;
        margin-bottom: 15px;

        h2,
        .numbg {
            font-family: 'exo';
            font-weight: normal;
            font-size: 40px;
        }

        .numbg {
            font-size: 30px;
            padding: 15px 55px;
            background: url(../assets/images/numbg.png);
            background-size: 100% 100%;
        }
    }

    .left {
        width: 500px;
        position: relative;

        .card {
            margin-bottom: 20px;
            width: 100%;
            padding: 15px 5px 5px;
            box-sizing: border-box;
            /* Note: backdrop-filter has minimal browser support */
            border-radius: 12px;
            color: #fff;
            backdrop-filter: blur(3px);
            border: 1px solid #676767;
            background: #000000;

            .box {
                display: flex;
                flex-direction: column;
                align-items: start;
                padding: 20px;
            }

            h3 {
                font-family: 'exo' !important;
                color: #fff;
                font-size: 24px;
                font-weight: normal;
            }

            p {
                margin-top: 25px;
                font-family: 'Inter';
                font-size: 20px;
                color: #fff;
                opacity: .8;
            }
        }
    }

    .riht {
        .pic {
            position: relative;
            width: 450px;
            padding-top: 50px;

            .text {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                background-repeat: no-repeat;
                width: 160px;
                line-height: 20px;
                padding: 20px;
                height: 80px;
                background-size: 100% 100%;
            }

            .text1 {
                top: 15%;
                left: 4%;
                transform: translate(-40%, 50%);
                padding: 20px 0;
                font-size: 14px;
                line-height: 1;
                font-weight: bold;
                padding-bottom: 45px;
                box-sizing: content-box;
                background-image: url('../assets/images/text1.png');
            }

            .text2 {
                display: none;
                right: 0;
                padding-right: 55px;
                transform: translate(50%, 40%);
                background-image: url('../assets/images/text2.png');
            }

            .text3 {
                bottom: 0;
                width: 120px;
                padding: 20px 10px 10px;
                box-sizing: content-box;
                text-align: center;
                transform: translate(-10%, 2%);
                background-image: url('../assets/images/text3.png');
            }

            img {
                width: 120%;
            }

            .clip {
                position: absolute;
                top: 46%;
                left: -2%;
                width: 125px;
                text-align: center;
                padding: 3px 5px 3px 3px;
                border-radius: 12px 0 15px 12px;
                background-color: #ffdb00;
                clip-path: polygon(0 0, 100% 0, 94% 13px, 94% 100%, 0 100%);

                .message {
                    width: 100%;
                    text-align: center;
                    padding: 5px 10px 5px 5px;
                    border-radius: 12px 0 15px 12px;
                    background-color: #fff;
                    clip-path: polygon(0 0, 100% 0, 94% 13px, 94% 100%, 0 100%);
                }
            }
        }
    }
}


.number {
    font-family: 'unb_lig';
    color: #fff;
}

.numberbg {
    padding: 15px 0;
    margin: 10px 0;
    height: 150px;
    width: 100%;
    position: relative;
    font-family: 'unb_b';

    img {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@media screen and (max-width:1400px) {
    .container {
        flex-direction: column;

        .left {
            margin-bottom: 40px;
            width: 95%;
        }
    }
}

@media screen and (max-width:800px) {
    .container {
        flex-direction: column;

        .left {
            margin-bottom: 40px;
            width: 95%;
        }
    }

    .title_conts {
        margin-bottom: 0px;
    }

    .title_conts img {
        width: 80%;
    }

    .container .riht .pic {
        width: 300px;
    }

}

@media screen and (max-width:460px) {

    .container .riht .pic {
        width: 200px;
    }
}

@media screen and (max-width:400px) {
    .title {
        width: 90%;
        margin: 60px auto 20px;

        img {
            width: 100%;
        }
    }

    .container .riht .pic {
        width: 100%;
        overflow: hidden;
    }
}
</style>